<template>
  <div id="app">
    <div class="app_div" v-if="!$route.meta.isFrame">
      <router-view />
    </div>
    <div class="app_div" v-else>
      <Largescreen></Largescreen>
    </div>
  </div>
</template>

<script>
import { isolationpointList,queryOrgOne } from "./api/SystemManagement/index";
import {
  listCounty,
  listRegion,
  nationList
} from "./api/QuarantineRegistration/quarantineRegistrationpop";
import { getCrowdTypeList } from "./api/RuleManagement/crowdAnalysis";
import { getToken } from "./utils/auth";
import Largescreen from "./views/Statistics/Largescreen.vue";

export default {
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor"))
    };
  },

  // watch: {
  //   $route(to, from) {
  //     console.log(to);
  //     if (to.path != "/ohealth/statisticalAnalysis/Largescreen") {
  //       removejscssfile("flexible.js", "js");
  //       console.log("$route -> removejscssfile", removejscssfile());
  //     }
  //   }
  // },
  components: {
    Largescreen
  },
  created() {
    if (!getToken()) return;

    this.isolationList();
    this.queryOne();
    this.listCounty();
    this.listRegion();
    this.nationList();
    this.rqtype();
  },

  mounted() {
    this.setClientSize();
    window.addEventListener("resize", () => this.setClientSize(), false);
  },

  methods: {
    setClientSize() {
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      this.$store.dispatch("setClientHeight", clientHeight);
      this.$store.dispatch("setClientWidth", clientWidth);
    },
    // 隔离点列表
    async isolationList() {
      try {
        let { data } = await isolationpointList();
        this.$store.commit("SET_ISOLATION_LIST", data.data);
      } catch (error) {}
    },

    // 隔离点信息
    async queryOne() {
      let params = {
        orgCode: this.userdoctor.orgCode
      };
      try {
        let { data } = await queryOrgOne({ params });
        this.$store.commit("SET_GLD", data.data);
      } catch (error) { }
    },

    // 国籍
    async listCounty() {
      try {
        let { data } = await listCounty();
        localStorage.setItem("nationalityselet", JSON.stringify(data.data));
        this.$store.commit("SET_NATIONALITY_SELECT", data.data);
      } catch (error) { }
    },

    // 省
    async listRegion() {
      try {
        let { data } = await listRegion();
        localStorage.setItem("dqlist", JSON.stringify(data.data));
      } catch (error) { }
    },

    // 民族
    async nationList() {
      try {
        let { data } = await nationList();
        this.$store.commit("SET_NATION_SELECT", data.data);
      } catch (error) { }
    },

    // 人群type
    async rqtype() {
      let { data } = await getCrowdTypeList();
      if (data.code == 200) {
        this.$store.commit("SET_TYPEPOP_SELECT", data.data);
      } else {
        this.$message.error(data.msg);
      }
    }
  }
};
</script>

<style >
* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

/*滚动条整体样式*/
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer !important;
}

/*滚动条滑块*/
*::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 8px rgba(86, 86, 86, 0.2);
  background: #c0c0c0;
  height: 13.1972%;
  transform: translateY(234.375%);
}

/*滚动条轨道*/
*::-webkit-scrollbar-track {
  cursor: pointer !important;
  width: 8px;
  height: 8px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background: rgba(204, 204, 204, 0);
}

.app_div {
  width: 100%;
  height: 100%;
}
</style>
