const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  roles: state => state.user.roles,
  permission: state => state.permission,
  hospitalId: state => state.hospitalId,
  app: state => state.app,
  dict: state => state.dict
}

export default getters
