// 引入工具函数
import {
  http,
  httpimg
} from '../index'



// 流调摸排统计、流调结果汇总、协查函件、隔离点人群分布
export const getEventPlaceList = params => http.post('/ohealth/api/v1/epidemicControl/smartScreen/surveyCount', params)
// 隔离点人员情况
export const isolationPersonCount = params => http.post('/ohealth/api/v1/epidemicControl/smartScreen/isolationPersonCount', params)
// 集中隔离统计
export const isolationCount = params => http.post('/ohealth/api/v1/epidemicControl/smartScreen/isolationCount', params)
 // 转运调度统计
export const transportCount = params => http.post('/ohealth/api/v1/epidemicControl/smartScreen/transportCount', params)
 // 街道接送人群分布
 export const communityPersonCount = params => http.post('/ohealth/api/v1/epidemicControl/smartScreen/communityPersonCount', params)
// 中高风险地区隔离人员
export const mediumHighRiskCount = params => http.post('/ohealth/api/v1/epidemicControl/smartScreen/mediumHighRiskCount', params)



